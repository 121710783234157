<template>
  <footer class="footer-container bg-dark pt-4">
    <div class="container">
      <div class="row py-4">

        <div class="col-lg-3 col-12 align-left">
          <div class="w-100">
            <a class="navbar-brand" href="index.html">
              <!--              <img class="w-100" src="@/assets/images/logo/apple-icon.png" alt="icon">-->
            </a>
          </div>
          <p class="text-light my-lg-4 my-2">
            The Barangay Malanday is one of the constituent barangays located in Valenzuela City
            that were established during the arrival of the Spaniards.
          </p>
          <ul class="list-inline footer-icons light-300">
            <li class="list-inline-item m-0">
              <a class="text-light" target="_blank" href="https://www.facebook.com/SKMalandayVC/">
                <i class='bx bxl-facebook-square bx-md'></i>
              </a>
            </li>
            <li class="list-inline-item m-0">
              <a class="text-light" target="_blank" href="https://www.linkedin.com/">
                <i class='bx bxl-linkedin-square bx-md'></i>
              </a>
            </li>
            <li class="list-inline-item m-0">
              <a class="text-light" target="_blank" href="https://www.whatsapp.com/">
                <i class='bx bxl-whatsapp-square bx-md'></i>
              </a>
            </li>
            <li class="list-inline-item m-0">
              <a class="text-light" target="_blank" href="https://www.flickr.com/">
                <i class='bx bxl-flickr-square bx-md'></i>
              </a>
            </li>
            <li class="list-inline-item m-0">
              <a class="text-light" target="_blank" href="https://www.medium.com/">
                <i class='bx bxl-medium-square bx-md'></i>
              </a>
            </li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-4 my-sm-0 mt-4">
          <h3 class="h4 pb-lg-3 text-light light-300">Our Barangay</h3>
          <ul class="list-unstyled text-light light-300">
            <li class="pb-2">
              <i class='bx-fw bx bxs-chevron-right bx-xs'></i><a class="text-decoration-none text-light"
                href="app:home">Home</a>
            </li>
            <li class="pb-2">
              <i class='bx-fw bx bxs-chevron-right bx-xs'></i><a
                class="text-decoration-none text-light py-1" href="app:government">About
                Us</a>
            </li>
            <li class="pb-2">
              <i class='bx-fw bx bxs-chevron-right bx-xs'></i><a
                class="text-decoration-none text-light py-1" href="app:work">Work</a>
            </li>
            <li class="pb-2">
              <i class='bx-fw bx bxs-chevron-right bx-xs'></i><a
                class="text-decoration-none text-light py-1" href="app:pricing">Price</a>
            </li>
            <li class="pb-2">
              <i class='bx-fw bx bxs-chevron-right bx-xs'></i><a
                class="text-decoration-none text-light py-1" href="app:contact">Contact</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-4 my-sm-0 mt-4">
          <h2 class="h4 pb-lg-3 text-light light-300">Our Events</h2>
          <ul class="list-unstyled text-light light-300">
            <li class="pb-2">
              <i class='bx-fw bx bxs-chevron-right bx-xs'></i><a
                class="text-decoration-none text-light py-1" href="#events">Events</a>
            </li>
            <li class="pb-2">
              <i class='bx-fw bx bxs-chevron-right bx-xs'></i><a
                class="text-decoration-none text-light py-1" href="#news">News</a>
            </li>
            <li class="pb-2">
              <i class='bx-fw bx bxs-chevron-right bx-xs'></i><a
                class="text-decoration-none text-light py-1" href="#project">Project</a>
            </li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-4 my-sm-0 mt-4">
          <h2 class="h4 pb-lg-3 text-light light-300">Our Contact</h2>
          <ul class="list-unstyled text-light light-300">
            <li class="pb-2">
              <i class='bx-fw bx bx-phone bx-xs'></i><a class="text-decoration-none text-light py-1"
                href="tel:8962-7327">8962-7327</a>
            </li>
            <li class="pb-2">
              <i class='bx-fw bx bx-mail-send bx-xs'></i><a class="text-decoration-none text-light py-1"
                href="mailto:info@company.com">skmalanday@gmail.com</a>
            </li>
          </ul>
        </div>

      </div>
    </div>

    <div class="w-100 bg-dark py-3">
      <div class="container">
        <div class="row pt-2">
          <div class="col-lg-12 col-sm-12">
            <p class="text-lg-start text-center text-light light-300">
              © Copyright 2021 ALPHA Developers. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>
