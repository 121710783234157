<template>
  <head>
    <title>oneBarangay</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">

    <link rel="icon" type="image/png" sizes="16x16" href="@/assets/images/favicon/favicon-16x16.png">
    <link rel="icon" type="image/png" sizes="32x32" href="@/assets/images/favicon/favicon-32x32.png">
    <link rel="icon" type="image/png" sizes="96x96" href="@/assets/images/favicon/favicon-96x96.png">
    <link rel="icon" type="image/png" sizes="194x194" href="@/assets/images/favicon/favicon-194x194.png">
    <link rel="icon" type="image/png" sizes="192x192" href="@/assets/images/favicon/android-chrome-192x192.png">

    <!-- Font CSS -->
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap" rel="stylesheet">
  </head>
</template>

<script>
import "@/assets/css/home/bootstrap.min.css";
import "@/assets/css/home/boxicon.min.css";
import "@/assets/css/home/templatemo.css";
import "@/assets/css/home/custom.css";

export default {
  name: "Base",
}
</script>

<style scoped>

</style>
